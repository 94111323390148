<template>
  <div>
    <CRow>
      <CCol col="12">
        <TweetFilter ref="TweetFilter"></TweetFilter>
      </CCol>
      <CCol sm="6">
        <CCard>
          <CChartBar
            :datasets="weekday_dataset"
            :labels="[
              'Sunday',
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
            ]"
          />
        </CCard>
      </CCol>
      <CCol sm="6">
        <CCard>
          <CChartBar :datasets="hour_dataset" labels="indexes" />
        </CCard>
      </CCol>
      <CCol sm="6">
        <CCard>
          <CChartPie
            :datasets="rt_dataset"
            :labels="['Retweets', 'no retweets']"
          />
        </CCard>
      </CCol>
      <CCol sm="6">
        <CCard>
          <CChartRadar
            :datasets="tag_dataset"
            :options="defaultOptions"
            :labels="['FDP', 'Union', 'SPD', 'dieGrünen', 'dieLinke', 'AFD']"
          />
        </CCard>
      </CCol>
      <CCol sm="12">
        <CCard>
          <CCardHeader> Top 5 Accounts in current statistic </CCardHeader>
          <CDataTable
            :items="users"
            :fields="fields"
            hover
            :sorter="{ external: false, resetable: true }"
            :sorterValue="{ column: 'filtered_tweets', asc:false }"
            :loading="usersLoading"
            @row-clicked="rowClicked"
            clickable-rows
          >
            <template #avatar="{ item }">
              <td>
                <img
                  v-if="item.json"
                  :src="item.json.profile_image_url"
                  class="c-avatar-img"
                  alt=""
                  style="max-width: 50px"
                />
              </td>
            </template>
            <template #tags="{ item }">
              <td>
                <CBadge
                  href="#"
                  color="dark"
                  v-for="(m, index) in item.tags"
                  :key="index"
                  class="mr-1"
                  >{{ m }}</CBadge
                >
              </td>
            </template>
            <template #name="{ item }">
              <td>
                <strong
                  >{{ item.name }}
                  <CIcon
                    v-if="item.verified === true"
                    name="cil-check-circle"
                  />
                </strong>
                <em>@{{ item.screen_name }}</em>
              </td>
            </template>
            <template #show_details="{ item, index }">
              <td class="py-2">
                <CButton
                  color="primary"
                  variant="outline"
                  square
                  size="sm"
                  @click="toggleDetails(index)"
                >
                  {{ Boolean(itemToggler[index]) ? "Hide" : "Show" }}
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TweetFilter from "./modules/TweetFilter";
import {
  CChartLine,
  CChartPie,
  CChartRadar,
  CChartBar,
} from "@coreui/vue-chartjs";
import { getDay, getHours } from "date-fns";
const fields = [
  {
    key: "avatar",
    label: "",
    _style: "width:100px",
    sorter: false,
    filter: false,
  },
  // {
  //     key: 'select',
  //     label: '',
  //     _style: 'width:1%',
  //     sorter: false,
  //     filter: false
  // },
  // {key: 'id', _style: 'width:1%'},
  {
    key: "name",
    label: "Name",
    filter: false,
  },
  { key: "tags", label: "Tags" },
  {
    key: "filtered_tweets",
    label: "Selection Tweets",
    filter: false,
    _style: "width:1%",
  },
  {
    key: "tweetnumber",
    label: "Total Tweets",
    filter: false,
    _style: "width:1%",
  },
  {
    key: "followers",
    filter: false,
    _style: "width:1%",
  },
  {
    key: "followings",
    filter: false,
    _style: "width:1%",
  },
];
export default {
  name: "Stats",
  components: {
    TweetFilter,
    CChartLine,
    CChartPie,
    CChartRadar,
    CChartBar,
  },
  data() {
    return {
      fields,
      details: [],
      collapseDuration: 0,
      itemToggler: [],
    };
  },
  watch: {},
  methods: {
    ...mapActions([
      "requestCurrentTwitterTweets",
      "requestCurrentTwitterUsers",
      "updateCurrentTweetsFilter",
    ]),
    getDateInFormat(date, formatstr) {
      if (typeof date === "string") {
        return format(new Date(date), formatstr, { locale: de });
      }
      return "-";
    },
    getFullProfilePicUrl(url) {
      return url.replace("_normal", "");
    },
    toggleDetails(index) {
      this.itemToggler[index] = !this.itemToggler[index];
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    rowClicked(item, index, column, e) {
      // this.toggleDetails(index)
      // if (column === 'name') {
      this.$router.push("/twitter/accounts/" + item.id);
      // }
    },
  },
  computed: {
    ...mapGetters([
      "getCurrentTwitterTweetsMeta",
      "getCurrentTwitterTweets",
      "getCurrentTwitterUsers",
      "getCurrentUsersLoading",
    ]),
    usersLoading() {
      return this.getCurrentUsersLoading;
    },
    aggregatetweets() {
      if (this.getCurrentTwitterTweets) {
        let days = [0, 0, 0, 0, 0, 0, 0];
        let hours = Array(24).fill(0);
        let retweet = [0, 0];
        let tags = [0, 0, 0, 0, 0, 0];
        let user_top_list = {};
        let sorted_top_list = [];
        this.getCurrentTwitterTweets.forEach((elem) => {
          days[getDay(elem.created)] = days[getDay(elem.created)] + 1;
          hours[getHours(elem.created)] = hours[getHours(elem.created)] + 1;
          user_top_list[elem.user_id] = (user_top_list[elem.user_id] || 0) + 1;
          if (elem.is_retweet) {
            retweet[0] = retweet[0] + 1;
          }
          if (elem.tags.length > 0) {
            elem.tags.forEach((tag) => {
              switch (tag.name) {
                case "FDP":
                  tags[0] = tags[0] + 1;
                  break;
                case "Union":
                  tags[1] = tags[1] + 1;
                  break;
                case "CDU":
                  tags[1] = tags[1] + 1;
                  break;
                case "SPD":
                  tags[2] = tags[2] + 1;
                  break;
                case "dieGrünen":
                  tags[3] = tags[3] + 1;
                  break;
                case "dieLinke":
                  tags[4] = tags[4] + 1;
                  break;
                case "AFD":
                  tags[5] = tags[5] + 1;
                  break;
              }
            });
          }
        });
        // create top list
        for (var a in user_top_list) {
          sorted_top_list.push([a, user_top_list[a]]);
        }
        sorted_top_list.sort(function (a, b) {
          return b[1] - a[1];
        });
        sorted_top_list = sorted_top_list.slice(0, 5);
        // create table of top accounts
        var top_ids = sorted_top_list.map(function (x) {
          return x[0];
        });
        var users_final = this.getCurrentTwitterUsers.filter(function (item) {
          return top_ids.indexOf(item.id) !== -1;
        });
        // add number of tweets in current stats
        users_final.forEach((user) => {
          user.filtered_tweets = 0;
          sorted_top_list.forEach((elem) => {
            if (user.id == elem[0]) {
              user.filtered_tweets = elem[1];
            }
          });
        });
        // add #retweets
        retweet[1] =
          Object.keys(this.getCurrentTwitterTweets).length - retweet[0];
        return {
          days: days,
          hours: hours,
          retweet: retweet,
          tags: tags,
          users_final: users_final,
        };
      }
    },
    users() {
      return this.aggregatetweets.users_final;
    },
    weekday_dataset() {
      return [
        {
          label: "Tweets per Weekday",
          backgroundColor: "#073247",
          data: this.aggregatetweets.days,
        },
      ];
    },
    hour_dataset() {
      return [
        {
          label: "Tweets per Hour",
          backgroundColor: "#073247",
          data: this.aggregatetweets.hours,
        },
      ];
    },
    rt_dataset() {
      return [
        {
          label: "Share of Retweets",
          backgroundColor: ["#87bd24", "#073247"],
          data: this.aggregatetweets.retweet,
        },
      ];
    },
    tag_dataset() {
      return [
        {
          label: "Tweets by tag",
          backgroundColor: "rgba(179,181,198,0.2)",
          borderColor: "rgba(179,181,198,1)",
          pointBackgroundColor: "rgba(179,181,198,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(179,181,198,1)",
          tooltipLabelColor: "rgba(179,181,198,1)",
          data: this.aggregatetweets.tags,
        },
      ];
    },
    defaultOptions() {
      return {
        aspectRatio: 1.5,
      };
    },
  },
  created() {
    if (this.getCurrentTwitterTweets.length === 0) {
      this.requestCurrentTwitterTweets();
      this.requestCurrentTwitterUsers();
    }
  },
};
</script>
